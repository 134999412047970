.Sidebar-listItem {
    justify-content: space-between!important;
    background-color: #F5F5F5;
    border-radius: 8px;
    padding: 12px!important;
    user-select: none;
}

.SideBar-KeyModeCTA {
    position: absolute!important;
    bottom: 0!important;
}

.router-link__active {
    pointer-events: none!important;
    cursor: not-allowed;
}

.router-link__active .Sidebar-listItem,
.Sidebar-listItem:hover
{
    background-color: #eee;
}